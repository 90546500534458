/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */

import window from "global/window";

// export default LandingPage;

import Layout from "components/Layout";
import withUser from "hoc/withUser";
import { FeatureClass } from "views/Home/FeatureClass";
import { Heading } from "views/Home/Heading";
import { Quality } from "views/Home/Quality";

const Homepage = ({ view }: { view: any }) => {
  return (
    <Layout>
      <>
        <Heading view={view} />
        <div className="flex flex-col mt-6 space-y-8">
          {/* <FeatureClass /> */}
          <FeatureClass name="Bài học nổi bật" />
        </div>
        {/* <FeatureClass name="Lớp học mới" number={4} /> */}
        {/*  */}

        <Quality />

        <div className="flex items-center justify-center w-full my-10">
          <div
            style={{
              height: "307px",
              width: "1200px",
              backgroundImage: 'url("/images/background.svg")',
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            className="flex flex-row text-white"
          >
            <div className="flex flex-col justify-center space-y-4 pl-14">
              <span className="text-4xl">
                Tham gia cộng đồng để nhận Tô miễn phí!
              </span>
              <span className="pr-8 text-2xl text-smoke">
                Cộng đồng học tập vui vẻ của Lớp Học 1 Tô trên Facebook
              </span>
              <button
                className="w-40 py-3 rounded-l-md bg-primary-pink border-primary-pink hover:bg-primary-bolder hover:border-primary-bolder"
                type="button"
                onClick={() => {
                  (window as any)
                    .open(
                      "https://www.facebook.com/groups/1418242408408705",
                      "_blank"
                    )
                    .focus();
                }}
              >
                Tham gia
              </button>
            </div>
            {/* <div>
              <img
                style={{ width: 900, height: "100%" }}
                src="/images/rhone.svg"
              />
            </div> */}
          </div>
        </div>
      </>
    </Layout>
  );
};

export default withUser(Homepage);
